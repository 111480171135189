section#customer-logos {
  padding-bottom: 60px;
  transition: height 0.3s;

  > .container {
    @media (min-width: 768px) {
      max-width: 100%;
    }

    @media (min-width: 992px) {
      max-width: 1120px;
    }

    @media (min-width: 1200px) {
      max-width: 1160px;
    }

    @media (min-width: 1280px) {
      max-width: 1240px;
    }

    @media (min-width: 1400px) {
      max-width: 1480px;
    }
  }

  p {
    margin-top: 60px;
    margin-bottom: 50px;
    text-align: center;
  }

  .sft-customers-container {
    row-gap: 2px;
  }

  .sft-customer-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    &.col-lg-2 {
      @media (min-width: 1200px) {
        flex: 0 0 14% !important;
        max-width: 14% !important;;
      }
    }

    &.col-md-3 {
      @media (min-width: 768px) {
        flex: 0 0 20%;
        max-width: 20%;
      }
    }

    img {
      transform: scale(0.9);
      transition: filter .3s ease-out;

      &:hover {
        filter: none;
      }
    }

    @media (max-width: $mobile-width) {
      min-height: 52px !important;
      height: 52px !important;

      img {
        transform: scale(.7);
      }
    }

    @media (max-width: $tablet-width) {
      min-height: 60px;
    }

    @media (min-width: $tablet-width) {
      min-height: 50px;
    }
  }


  @media (min-width: $desktop-width) {
    .container-fluid {
      width: 90%;
    }

    .sft-customer-logo-en {
      flex: 0 0 12.5%;
      max-width: 12.5%;
    }
  }
}