$border-radius: 2px;

body, html {
  height: 100%;
}

body {
  font-family: $p-font;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.5;
  font-weight: normal;
}

/* anchor-point */
a {
  @include transition (all 0.3s);
  text-decoration: none;
  color: $primary;

  &:hover, &:focus {
    text-decoration: none;
    outline: 0 !important;
    color: $primary-dark;
  }
}

.bold {
  font-weight: 600;
}

.text-center {
  text-align: center;
}

// media-text-center (lg, md, sm)
$screens: ("lg" $desktop-width, "md" $laptop-width, "sm" $tablet-width, "xs" $mobile-width);
@each $name, $screen in $screens {
  .sft-#{$name}-text-center {
    @media (max-width: $screen) {
      text-align: center !important;
    }
  }
}

.sft-color-primary {
  color: $primary;
}

.sft-color-primary-dark {
  color: $primary-dark;
}

// header
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-family: $p-font;
  font-weight: 700;
  color: $body-color;
  line-height: normal;
  letter-spacing: normal;
}

h1 {
  font-size: 1.75rem;
  line-height: 1.32;
  margin-bottom: 1.5rem;

  @media (min-width: $mobile-width) {
    font-size: 3.25rem;
    line-height: 1.36;
  }
}

h2 {
  font-size: 1.5rem;
  line-height: 1.3;

  @media (min-width: $mobile-width) {
    font-size: 2.5rem;
    line-height: 1.3;
  }
}

h3 {
  font-size: 1.5rem;

  @media (min-width: $mobile-width) {
    font-size: 2rem;
  }
}

p {
  color: $body-color;
  line-height: 1.5;

  &.sft-text-24 {
    font-size: 24px;

    @media (max-width: $mobile-width) {
      font-size: 1rem;
    }
  }

  &.sft-text-20 {
    font-size: 20px;
    line-height: 1.75;

    @media (max-width: $mobile-width) {
      font-size: 1rem;
    }
  }

  &.sft-text-15 {
    font-size: 15px;

    @media (max-width: $mobile-width) {
      font-size: .875rem;
    }
  }

  &.sft-text-14 {
    font-size: .875rem;
  }
}

input {
  padding-left: 16px;
  font-size: 15px;
  border-radius: $border-radius;
  border-color: $text-gray;
}

/** spaces **/
.space-40 {
  width: 100%;
  height: 40px;
  clear: both;
}

// padding top, bottom 120px
.sft-py-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.sft-flex-column {
  flex-direction: column;
}

.sft-align-center {
  justify-content: center;
  align-items: center;
}

@media(min-width: $tablet-width) {
  .vertical-align-child {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}

.container {
  @media (min-width: $mobile-width) {
    max-width: 100%;
  }

  @media (min-width: $tablet-width) {
    max-width: 720px;
  }

  @media (min-width: $laptop-width) {
    max-width: 960px;
  }

  @media (min-width: $desktop-width) {
    max-width: 1140px;
  }

  .dropdown-menu {
    @include shadow-md;
    padding: 15px 0;
    border: none;
    margin-top: 21px;
    border-radius: 0 4px 4px;
    font-size: 15px;
  }
}

/** buttons **/

/**
 * name: (background-color, font-color)
 */
$btn-colors: (
        "primary": ($primary, white),
        "primary-white": ($primary, white),
        "white": (white, $primary),
        "transparent": (transparent, $primary)
);

%btn {
  @include reset-button;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  font-family: $p-font;
  padding: 1em 3em;
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
  white-space: nowrap;
  @include transition(0.35s ease-in-out);
}

//btn-color
@each $name, $colors in $btn-colors {
  $bg-color: nth($colors, 1);
  $font-color: nth($colors, 2);

  .btn-#{$name} {
    @extend %btn;

    background-color: $bg-color;
    border: 1px solid $bg-color;
    color: $font-color !important;

    @if ($name == "transparent") {
      border: 1px solid $font-color !important;
    }

    &:hover, &:focus {
      @if ($name == "white") {  }
      @else if ($name == "primary") {
        background-color: $primary-dark;
        border-color: $primary-dark;
      }
      @else if ($name == "transparent") {
        background-color: $font-color;
        color: white !important;
      }
      @else {
        background-color: $font-color;
        color: $bg-color !important;
      }
    }
  }
}

//btn-size
.btn-lg {
  @include border-radius(2px);

  &:hover, &:focus {
    @include transform(translateY(-2px));
  }

  &:focus {
    outline: none;
  }
}

.btn-sm {
  font-size: 14px;
  padding: 1em 1.8em;

  @media (max-width: $laptop-width) {
    width: 100%;
  }
}

//btn-other
.btn-flat {
  @extend %btn;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $primary;
}

/** shadows **/
.sft-shadow {
  &-lg {
    @include box-shadow(0 8px 24px 0 $shadow-black);
  }
  &-md {
    @include shadow-md
  }
  &-sm {
    @include shadow-sm
  }
}

/* For bootstrap4. Will remove below after migrate to 4*/
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

/* Customer Logos Summary */
section#sft-customer-logo-summary {
  padding: 3rem 0 2rem;

  @keyframes sftCustomerLogosRotation {
    from { transform: translateX(0px); }
    to { transform: translateX(calc(-100% - 36px)); }
  }

  .row.sft-customer-logos-rotation-container {
    display: flex;
    gap: 36px;
    overflow: hidden;
    flex-wrap: nowrap;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background-image: linear-gradient(90deg, white 0, transparent 20%, transparent 80%, white 100%);
    }
  }

  .sft-customer-logos-container {
    display: flex;
    gap: 36px;
    flex-shrink: 0;
    justify-content: space-around;
    min-width: 100%;
    animation: sftCustomerLogosRotation 52s linear 0s infinite running;
  }

  .sft-customer-logos-container-en {
    gap: 42px;
    align-items: center;
    justify-content: center;
  }

  .sft-customer-logo-img {
    min-width: 120px;
    text-align: center;

    img {
      filter: saturate(0);
    }
  }
}

section#sft-main-features {
  padding: 80px 0;
  background-color: #f0f1f3;

  h3 {
    text-align: center;
  }

  .sft-feature-container {
    margin: 0 0 2rem;
    padding: 20px 24px 10px;
    border-radius: 4px;
    background-color: white;
    transition: all .3s;
    border: 1px solid white;

    .sft-img-container {
      margin-left: -2px;
      margin-right: 7px;
    }

    .sft-feature-title {
      flex: 1;

      p.sft-text-20 {
        display: inline-block;
        word-break: keep-all;
        font-weight: bold;

        @media (min-width: $mobile-width) {
          font-size: 18px;
        }
      }

      i {
        opacity: 0;
        margin-top: 3px;
        float: right;
        transition: all .3s;
      }
    }

    &:hover {
      border-color: $primary;
      transform: translateY(-2px);

      .sft-feature-title {
        p.sft-text-20 {
          color: $primary;
        }

        i {
          opacity: 1;
          transform: translateX(-4px);
        }
      }
    }
  }
}
#sft-index-features-section {
  & > .container {
    @media (min-width: $desktop-width) {
      max-width: 1200px;
    }
  }

  padding: 60px 0;
  background-color: $base-darker;

  p.sft-tag {
    margin-left: 4px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  h2 {
    margin-bottom: 2rem;
  }

  img.sft-feature-icon {
    margin-bottom: 16px;
    width: 48px;
    height: 48px;
  }

  .sft-feature {
    padding-top: 20px;
    padding-bottom: 28px;
    border-radius: 4px;
    transition: .3s ease-in-out;

    @media (max-width: $mobile-width) {
      border: 1px solid #d8dfe8;
      margin-bottom: 8px;
    }

    .sft-text-20 {
      margin-bottom: 12px;

      @media (min-width: $laptop-width) {
        font-size: 18px;
      }
    }

    .sft-text-15 {
      margin-bottom: 0;
      word-break: keep-all;

      @media (max-width: $mobile-width) {
        margin-bottom: 1.5rem;
      }
    }

    .sft-learn-more {
      font-size: 14px;
    }

    i.fa-arrow-right {
      color: $primary;
      position: absolute;
      bottom: 1rem;
      opacity: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: opacity .3s;
    }

    &:hover {
      background: white;
      transition: .3s ease-in-out;

      i.fa-arrow-right {
        opacity: 1;
      }
    }

    @media (min-width: $tablet-width) {
      padding-top: 27px;
      padding-bottom: 40px;
    }
  }

  .sft-additional-features {
    position: relative;
    padding-top: 48px;
    margin-top: 3rem;
    border-top: 1px solid #9fa5ad;

    i.fa-plus {
      padding: 0 1rem;
      position: absolute;
      top: -58px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 20px;
      color: #9fa5ad;
      background-color: $base-darker;
    }

    .sft-addon {
      .sft-addon-container {
        padding: 1.5rem 1rem 1.25rem;
        height: 100%;
        display: flex;
        border-radius: 8px;
        transition: all .3s;

        img {
          margin-right: 1.25rem;
          margin-top: -4px;
          width: 48px;
          height: 48px;
        }

        .sft-addon-text-container {
          height: 100%;

          p.bold span {
            margin-right: 4px;
            float: right;
            color: $primary;
            opacity: 0;
            transition: opacity .3s;
          }

          p.sft-description {
            margin-bottom: 0;
            word-break: keep-all;
          }
        }

        @media (max-width: $laptop-width) {
          margin-bottom: 24px;
          height: inherit;
        }
      }

      &:hover {
        .sft-addon-container {
          background-color: white;

          p.bold span {
            opacity: 1;
          }
        }
      }
    }
  }
}