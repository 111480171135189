/** vendor-prefix **/
//interpolation
@mixin vendor-prefix($property, $value, $prefixes: ("moz", "o", "ms", "webkit")) {
  @each $prefix in $prefixes {
    -#{$prefix}-#{$property}: $value;
  }
  #{$property}: $value;
}

//transition
@mixin transition($transition...) {
  @include vendor-prefix(transition, $transition);
}

//transform
@mixin transform($transforms) {
  @include vendor-prefix(transform, $transforms);
}

//border-radius
@mixin border-radius($radius) {
  @include vendor-prefix(border-radius, $radius);
}

//box-shadow
@mixin box-shadow($shadow) {
  @include vendor-prefix(box-shadow, $shadow);
}


/** base **/
//reset
@mixin reset-button {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

//shadows
@mixin shadow-lg {
  @include box-shadow(0 8px 24px 0 $shadow-blue);
}

@mixin shadow-md {
  @include box-shadow(0 8px 13px 0 $shadow-blue);
}

@mixin shadow-sm {
  @include box-shadow(0 4px 6px 0 $shadow-blue);
}

//animations
@mixin sft-up-down-anim($keyframe-to, $duration) {
  @keyframes move-up-and-down {
    from { transform: translateY(0); }
    to { transform: translateY($keyframe-to); }
  }
  animation: move-up-and-down $duration infinite alternate cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

@mixin sft-one-count-anim($duration) {
  $name: anim-#{unique-id()};

  @keyframes #{$name} {
    @content
  }

  animation: $name $duration ease-in-out 0s 1;
}

/** product **/
@mixin sft-video($padding-top) {
  padding-top: $padding-top;
  position: relative;
  overflow: hidden;

  @content
}

@mixin sft-feature-list-responsive($screen-size) {
  @media (max-width: $screen-size) {
    display: flex;
    flex-flow: column;
    list-style: none;

    li {
      margin: auto;
    }
  }
}