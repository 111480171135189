footer {
  .container.sft-xxl-container {
    @media (max-width: 992px) {
      max-width: 100%;
    }

    @media (min-width: 992px) {
      max-width: 990px;
    }

    @media (min-width: 1200px) {
      max-width: 1200px;
    }

    @media (min-width: 1300px) {
      max-width: 1240px;
    }
  }

  .sft-navs {
    height: auto;
    padding-top: 70px;
    background: white;

    @media (min-width: $tablet-width) {
      height: 820px;
    }

    @media (min-width: $laptop-width) {
      height: 560px;
    }

    .row {
      .col-lg-2 {
        @media (min-width: $laptop-width) {
          flex: 0 0 18%;
          max-width: 18%;
        }

        @media (min-width: $desktop-width) {
          flex: 0 0 16%;
          max-width: 16%;
        }
      }

      @media (max-width: $tablet-width) {
        margin-bottom: 16px;
        justify-content: center;
      }

      .footer-menu {
        @media (max-width: $laptop-width) {
          margin-top: 3rem;
        }
      }
    }
  }

  .sft-company-logo {
    margin-bottom: 35px;
  }

  .sft-company-info {
    font-size: 13px;
    line-height: 20px;

    @media (max-width: $mobile-width) {
      margin-bottom: 60px;
    }

    #sft-chat-with-us-btn {
      color: $primary;
      cursor: pointer;
    }
  }

  h4 {
    margin-bottom: 24px;
    font-size: 12px;
  }

  hr {
    width: 80%;
    margin: 1.5rem 0;

    @media (max-width: $mobile-width) {
      width: 90px;
      margin: 1rem 0;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;

    li {
      margin-bottom: 12px;
      font-size: 14px;

      a {
        color: $body-color;

        span.sft-event-tag {
          padding: 4px 6px;
          margin-right: 6px;
          color: white;
          font-weight: bold;
          font-size: 12px;
          background-color: $primary;
          border-radius: 4px;
        }

        span.sft-new-tag {
          padding: 4px 6px;
          margin-right: 6px;
          color: white;
          font-weight: bold;
          font-size: 12px;
          background-color: $primary;
          border-radius: 4px;
        }
      }
    }
  }

  .container-fluid {
    padding: 36px 0;
    background: $base;

    a {
      color: #969faa;
    }

    .row {
      align-items: center;
    }

    ul.dropdown-menu {
      top: -30px !important;
    }

    .sft-footer-navs {
      display: flex;
      margin-bottom: 0;

      li:not(:last-child) {
        margin-right: 48px;
        margin-bottom: 0;

        @media (max-width: $mobile-width) {
          margin-right: 24px;
        }
      }
    }

    .sft-appstore-badge {
      margin-left: auto;

      @media (max-width: $mobile-width) {
        margin: 36px auto;
      }
    }
  }

  .sft-footer-bottom {
    @media (max-width: $tablet-width) {
      text-align: center;

      .sft-banners {
        margin-top: 36px;
      }

      .sft-footer-navs {
        justify-content: center;
        text-align: center;
      }
    }
  }
}
