//some variables for ASSAN theme, you can create your own easily.
$body-bg: #fff;
$body-color: #1e1f21;
$base-line-height: 24px;
$p-font: 'Gothic A1', sans-serif; //default font family
$alert-color: red; //alert color

$primary: #004dc1;
$primary-dark: #0a2864;
$bg-blue: #1c87f4;
$red: #e53864;
$green: #29ccbc;
$dark: #242b43;
$assort-dark: #1f2d4d;
$base: #f4f7fb;
$base-darker: #f0f1f3;

$light: #cde2fd;
$text-secondary: #54595f;
$text-gray: #969faa;
$border-color: #c2cad5;
$shadow-black: rgba(54, 55, 61, 0.25);
$shadow-blue: rgba(159, 176, 199, 0.3);
$desktop-width: 1200px;
$laptop-width: 992px;
$tablet-width: 768px;
$mobile-width: 576px;

$nav-height-lg: 107px;
$nav-height: 64px;

/**colors**/
.red {
  color: $red;
}

.green {
  color: $green;
}