/** Google Fonts **/
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Gothic+A1:wght@400;500;700;800&display=swap');

/* Shared Styles */
@import "variables";
@import "mixins";
@import "base";

/* Common Layout */
@import "../../common/nav";
@import "../../common/footer";
@import "../../common/customerLogos";