@import "common/main";

h1 {
  margin-top: 120px;
}

p {
  &.sft-text-15 {
    line-height: 1.75;
  }
}

.container {
  padding: 0 25px;

  @media (min-width: $desktop-width) {
    padding: 0 32px;
  }
}

.sft-no-flex {
  flex: 0;
}

nav {
  .navbar-brand img {
    margin-bottom: 6px;

    &.sft-logo-primary {
      margin-right: -2px;
    }
  }
}

.modal#sft-brand-video-modal .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 90%;
  max-width: 90%;
  @include transform(translate(-50%, -50%) !important);

  .modal-body {
    padding: 0;
  }

  @media (min-width: $tablet-width) {
    width: 80%;
    max-width: 80%;
  }

  @media (min-width: $desktop-width) {
    width: 960px;
    max-width: 960px;
  }
}

#intro-jumbotron {
  padding: 180px 0 210px;
  position: relative;
  background-color: $primary-dark;
  overflow: hidden;

  .sft-jumbo-bg {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: -8%;
    width: 120%;
    opacity: .5;

    @media (min-width: $laptop-width) {
      width: 80%;
      right: -10%;
      opacity: 1;
    }

    @media (min-width: $desktop-width) {
      width: 68%;
      right: 0;
    }

    @media (min-width: 1400px) {
      width: 54%;
      right: 3%;
    }

    @media (min-width: 2000px) {
      top: 60px;
      right: 10%
    }
  }

  .sft-button-container {
    a {
      font-size: 1rem;

      &.btn-white {
        opacity: .95;

        &:hover, &:focus {
          opacity: 1;
        }
      }

      &.btn-transparent {
        margin-left: 1rem;
        color: white !important;
        border-color: white !important;

        @media (max-width: $laptop-width) {
          margin-left: 0;
          margin-top: 1rem;
        }

        &:hover, &:focus {
          background-color: white !important;
          color: $primary !important;
        }
      }
    }

    a.sft-video-btn {
      margin-left: 1.5rem;
      color: white;
      font-weight: normal;

      i {
        transition: transform .3s;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        i {
          transform: translateX(4px);
        }
      }

      @media (max-width: $laptop-width) {
        display: block;
        margin: 1rem 0 0 0;
      }
    }
  }

  h1 {
    font-size: 58px;
    margin-bottom: 42px;
    word-break: keep-all;

    @media (max-width: $mobile-width) {
      font-size: 48px;
    }
  }

  p.sft-text-20 {
    margin-bottom: 32px;
    opacity: .68;
  }

  h1, p {
    color: white;
  }

  video {
    background: #064ea4;
  }
}

#why-shiftee {
  padding: 60px 0;
  background-image: linear-gradient(white 70%, $base-darker 70%);

  .sft-container {
    padding: 64px 80px 70px;
    background-color: #13192d;
    border-radius: 1rem;
    position: relative;
    background-image: linear-gradient(170deg, #21316b 10%, #13192d 80%);
    box-shadow: 0px 12px 36px 0px #27346050, 0px 4px 12px #27346060;

    .sft-dotted-background {
      position: absolute;
      background-image: radial-gradient(white 2px, transparent 0);
      background-size: 36px 36px;
      background-repeat: repeat;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0;
      opacity: 0.2;
      mix-blend-mode: overlay;
      overflow: hidden;
    }

    @media (max-width: $mobile-width) {
      padding: 60px 24px;
    }
  }

  h2, p {
    color: white;
    word-break: keep-all;
  }

  p.sft-text-15 {
    margin-right: 8px;
  }

  h2 {
    margin-bottom: 70px;
  }

  p.sft-tag {
    margin-left: 4px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  p.sft-text-20 {
    font-weight: 800;
  }

  .sft-img-container {
    margin: 0 0 24px 2px;
    padding: 8px;
    width: 44px;
    height: 44px;
    background-image: linear-gradient(white 45%, rgba(255, 255, 255, 0.6));
    border-radius: 4px;

    img {
      width: 100%;
    }
  }

  & > .container {
    @media (min-width: 1200px) {
      max-width: 1160px;
    }
    @media (min-width: 1280px) {
      max-width: 1240px;
    }
  }
}

#platforms {
  padding: 100px 0;

  .sft-platform-container {
    padding: 2rem 3rem;
    border-radius: 4px;
    background-color: #edf6fe;
    min-height: 350px;
    overflow: hidden;
    transition: transform .3s;
    display: flex;
    flex-flow: column;
    justify-content: center;
    border: 1px solid #edf6fe;

    p.sft-tag {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: bold;
    }

    p.sft-text-20 {
      position: relative;
      z-index: 1;
    }

    p.sft-text-15 {
      margin-top: 24px;
      z-index: 1;
      position: relative;
      word-break: keep-all;
    }

    &.sft-mobile-web {
      .sft-img-container {
        position: absolute;
        top: -60px;
        left: -50px;

        img {
          width: 420px;
        }
      }
    }

    &.sft-desktop {
      .sft-img-container {
        position: absolute;
        top: -60px;
        left: -80px;

        img {
          width: 640px;
        }
      }
    }

    &:hover {
      transform: scale(1.02);
    }
  }

  .sft-img-container {
    @media (max-width: $mobile-width) {
      position: unset !important;

      img {
        width: 100% !important;
      }
    }
  }
}

#global {
  padding: 160px 0 0;
  position: relative;

  .sft-video {
    @include sft-video(158.6%);
    margin-top: -120px;
    border-radius: 0 220px 0 0;

    .sft-iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    @media (max-width: $laptop-width) {
      margin-top: 0;
    }
  }

  h2 {
    margin-bottom: 40px;
  }

  .sft-stats-row {
    margin-top: 3.5rem;
  }

  .sft-stats-container {
    padding: 40px 0;
  }

  .sft-stats-text {
    margin: 0 0 0 -4px;
    font-size: 2.5rem;
    font-weight: 700;

    @media (max-width: $laptop-width) {
      font-size: 2rem;
    }
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 27%;
    bottom: 120px;
    background-color: $base;

    @media (max-width: $laptop-width) {
      height: 24%;
      bottom: 0;
    }

    @media (max-width: $mobile-width) {
      content: none;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 60px 0 0;
  }

  .sft-background-color {
    height: 120px;
    background-color: $base;
  }
}

#industries {
  padding: 60px 0;
  background-image: linear-gradient(white, $base);

  p.sft-tag {
    margin-left: 4px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  .sft-industry-container {
    text-align: center;
    margin: .5rem 1rem;

    .sft-industry-icon-container {
      margin-bottom: .75rem;

      img {
        padding: 1rem;
        width: 100px;
        background: white;
        border-radius: 12px;

        @media (max-width: $laptop-width) {
          padding: .75rem;
          width: 88px;
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    padding: 24px 0 60px;
  }
}

.sft-service-container {
  .sft-text-14 {
    margin-bottom: 12px;

    @media (min-width: $tablet-width) {
      margin-bottom: 16px;
    }
  }

  h2 {
    margin-top: 12px;
    margin-bottom: 30px;
    word-break: keep-all;

    @media (min-width: $tablet-width) {
      margin-bottom: 16px;
    }
  }

  .sft-text-20 {
    margin-bottom: 36px;
    font-size: 16px;
    word-break: keep-all;

    @media (min-width: $laptop-width) {
      margin-bottom: 48px;
    }
  }

  a {
    i.fa-arrow-right {
      transition: .3s transform;
    }

    &:hover {
      i.fa-arrow-right {
        transform: translateX(4px);
      }
    }
  }

  a.btn-lg {
    @media (max-width: $mobile-width) {
      margin-bottom: 60px;
    }
  }

  img.sft-feature-img-shadow {
    border-radius: 4px;
    border: 1px solid #e8eaec;
    box-shadow: 0 8px 24px rgba(54, 55, 61, .1);
  }
}

#scheduler {
  padding: 100px 0 60px;
  transition: all 0.3s;
  max-width: 100%;
  overflow: hidden;

  h2 {
    margin-left: -4px;
    word-wrap: break-word !important;
    word-break: inherit;
  }

  .sft-image-container {
    position: relative;
  }

  .sft-feature-img-bg {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: calc(100% + 72px);
      right: 0;
      top: -36px;
      background-color: $base;
      z-index: -1;
    }
  }

  @media (max-width: $laptop-width) {
    padding-top: 100px;

    .container > .row {
      justify-content: center;
    }

    .sft-image-container {
      margin-bottom: 70px;
    }

    h2, p, a {
      display: block;
      text-align: center;
    }
  }
}

#timeclock {
  padding: 60px 0;
  max-width: 100%;
  overflow: hidden;

  h2 {
    margin-left: -5px;
  }

  .sft-image-container {
    position: relative;

    img {
      width: 55%;
    }
  }

  .sft-feature-img-bg {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: calc(100% - 140px);
      left: 0;
      top: 70px;
      background-color: $base;
      z-index: -1;
    }
  }

  @media (max-width: $laptop-width) {
    .container > .row {
      justify-content: center !important;
    }

    .sft-image-container {
      margin-bottom: 80px;
    }

    h2, p, a {
      display: block;
      text-align: center;
    }
  }
}

#leave {
  padding: 60px 0;
  overflow: hidden;
  background-image: linear-gradient(white, $base);

  .sft-image-container {
    img {
      width: 100%;
      height: auto;
    }

    @media (max-width: $laptop-width) {
      margin-top: 0;
      margin-bottom: 48px;
    }
  }
}

#workflow {
  padding: 60px 0 0;
  background-color: $base;

  @media (max-width: $laptop-width) {
    .container > .row {
      justify-content: center !important;
    }

    .sft-image-container {
      margin-bottom: 48px;
    }

    h2, p, a {
      display: block;
      text-align: center;
    }
  }

  .sft-custom-workflow {
    p {
      word-break: keep-all;
    }
  }
}

#add-on {
  padding: 100px 0 60px;

  .sft-add-on-feature-container {
    width: 100%;
    background-color: $base;
    border: 1px solid $base;
    border-radius: 12px;
  }

  .sft-description {
    margin: 24px 8px 16px;
    display: flex;

    .sft-description-block {
      margin-top: 4px;
      margin-right: 8px;
      width: 5px;
      height: 16px;
      background-color: $primary;
    }
  }

  .sft-text-container {
    padding: 2rem 2rem 0;
    margin-bottom: -0.5rem;

    .sft-tag {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 13px;
    }

    p {
      word-break: keep-all;
    }
  }
}

#report {
  padding: 100px 0;

  .sft-feature-img-bg {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 80%;
      height: calc(100% + 72px);
      left: 0;
      top: -36px;
      background-color: $base;
      z-index: -1;
    }
  }

  @media (max-width: $laptop-width) {
    .container > .row {
      justify-content: center !important;
    }

    .sft-image-container {
      margin-bottom: 48px;
    }

    h2, p, a {
      display: block;
      text-align: center;
    }
  }
}

#testimonials {
  padding: 80px 0 100px;
  position: relative;
  background-color: $base-darker;
  overflow: hidden;

  .sft-carousel-container {
    padding: 2rem 1rem;
    opacity: .4;
    transition: all .5s;

    .sft-testimonial-container {
      padding: 60px 24px;
      width: 100%;
      background-color: white;
      border-radius: 8px;
      transform: scale(.95);

      .img-container {
        position: relative;
        height: 36px;

        img {
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          right: 0;
          left: 0;
          bottom: 0;
        }
      }

      p {
        margin-top: 36px;
        word-break: keep-all;
        font-size: 15px;
        text-align: center;
        line-height: 2;
      }

      @media (min-width: $tablet-width) {
        padding: 60px 36px;

        p {
          font-size: 18px;
        }
      }

      @media (min-width: $laptop-width) {
        padding: 60px;
      }
    }

    &.slick-current {
      opacity: 1;

      .sft-testimonial-container {
        transform: scale(1);
      }
    }

    @media (max-width: $tablet-width) {
      padding: 1.5rem .5rem;
    }
  }

  button.slick-prev, button.slick-next {
    width: 25%;
    height: 100%;

    &::before {
      content: none;
    }

    @media (max-width: $tablet-width) {
      width: 10%;
    }
  }

  button.slick-prev {
    left: 0;
    z-index: 2;
  }

  button.slick-next {
    right: 0;
  }
}

#startups {
  padding: 48px 0 80px;
  overflow: hidden;

  h3 {
    margin: 24px 0;
    word-break: keep-all;
  }

  p {
    margin-bottom: 32px;
    word-break: keep-all;
  }

  a {
    margin-top: 24px;
  }

  .sft-startup-logo {
    display: flex;
    align-items: center;

    img {
      transform: scale(1);
      filter: saturate(0);
      transition: filter .3s ease-out;

      &:hover {
        filter: none;
      }
    }

    @media (max-width: $mobile-width) {
      min-height: 36px !important;

      img {
        transform: scale(.65);
      }
    }

    @media (max-width: $tablet-width) {
      min-height: 48px;
    }

    @media (max-width: $laptop-width) {
      justify-content: center;
    }

    @media (min-width: $tablet-width) {
      min-height: 48px;
    }

    @media (max-width: $desktop-width) {
      img {
        transform: scale(.85);
      }
    }
  }

  img.sft-startup-img {
    width: 100%;
  }

  @media (min-width: $laptop-width) {
    h2 {
      margin-top: 0;
    }

    .sft-img-container {
      max-height: 420px;

      img.sft-startup-img {
        width: 150%;
      }
    }
  }
}

#integration {
  padding-top: 30px;
  padding-bottom: 20px;

  h3, p {
    word-break: keep-all;
  }

  ul {
    li {
      margin-bottom: 1.25rem;

      b {
        line-height: 2.5;
        font-size: 16px;
      }
    }
  }

  & > .container {
    @media (min-width: 1200px) {
      max-width: 1160px;
    }
    @media (min-width: 1280px) {
      max-width: 1240px;
    }
  }
}

#solutions {
  padding: 60px 0;
  background: $base;

  h2 {
    margin-bottom: 72px;
  }

  .sft-solutions-box {
    padding: 36px 20px 24px;
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 1px solid #c0c6d9;
    background-color: white;
    border-radius: 4px;
    transition: all .3s;

    p {
      margin-bottom: 0;
      word-break: keep-all;
    }

    i {
      margin-bottom: 6px;
      transition: all .3s;
    }

    &:hover, &:focus {
      transform: translateY(-4px);

      i.fa-arrow-right {
        transform: translateX(4px);
      }
    }

    @media (max-width: $tablet-width) {
      padding: 24px;
    }
  }

  .col-md-4 {
    @media (max-width: $tablet-width) {
      margin-bottom: 24px;
    }
  }
}

#security {
  padding: 120px 0;
  background-color: #252647;
  overflow: hidden;

  h2 {
    margin-bottom: 56px;
  }

  h2, p {
    color: white;
  }

  p {
    padding: 4px 0;
  }

  i.fa-check {
    color: $primary !important;
  }

  a {
    color: white;
    font-size: 16px;
    display: block;

    i {
      transition: all .3s;
    }

    &:hover, &:focus {
      i.fa-arrow-right {
        transform: translateX(4px);
      }
    }
  }

  .sft-video-container {
    .sft-video {
      @include sft-video(64%);
      position: absolute !important;
      width: 140%;
      bottom: -520px;
      left: -60%;

      .sft-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }

      @media (min-width: $tablet-width) {
        padding-top: 70%;
        width: 150%;
        bottom: -620px;
        left: -60%;
      }

      @media (min-width: $laptop-width) {
        padding-top: 135%;
        width: 290%;
        bottom: -200px;
        left: -88%;
      }

      @media (min-width: $desktop-width) {
        padding-top: 100%;
        width: 210%;
        bottom: -200px;
        left: -68%;
      }
    }
  }
}

#call-to-action {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding: 120px 0;
  background-color: #074499;

  img {
    top: 0;
    position: absolute;
    height: 100%;

    @media (min-width: 1790px) {
      width: 100%;
    }
  }

  p, h2 {
    color: $base;
  }

  p {
    margin-bottom: 25px;
    opacity: 0.62;

    @media (min-width: $laptop-width) {
      margin-bottom: 32px;
    }
  }

  h2 {
    margin-bottom: 60px;
    font-size: 32px;
    word-break: keep-all;

    @media (min-width: $laptop-width) {
      margin-bottom: 32px;
      font-size: 46px;
    }
  }

  .sft-buttons {
    .btn i {
      font-size: 18px;
    }

    .sft-getting-started-btn {
      color: white !important;
      border-color: white !important;

      &:hover, &:focus {
        background-color: white;
        color: $primary !important;
      }

      @media (min-width: $tablet-width) {
        margin-left: 16px;
      }
    }

    @media (max-width: $tablet-width) {
      display: flex;
      width: 100%;
      flex-direction: column;

      a {
        width: 100%;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }
  }
}
